import React from 'react'
import { graphql } from "gatsby"
// import Img from 'gatsby-image'
import Pagination from '../components/Pagination'
import ArticleList from "../components/ArticleList"
import SEO from '../components/seo'
// import Search from '../components/Search'

export default function BlogList( props ) {

  const { data, pageContext } = props;
  const { allMdx, site } = data
  const posts = allMdx.edges.map(item => item.node) || null
  // const image = file;

    return (
        <div>
        <SEO 
          title={`Blog - Page ${pageContext.currentPage}`}
          description="My little blog about tech, side projects, entrenpreneurship & cool things."
          article={false}
       />
        
        <div className="blogIndex">
          {posts ? (
            <div className="container">

              <div className="row justify-content-center">
              <div className="col-12 d-flex justify-content-between alignt-items-center">
              <h1 className="giant primary-color">Blog</h1>
              <Pagination
                currentPage={pageContext.currentPage}
                totalCount={data.allMdx.totalCount}
                postsPerPage={site.siteMetadata.postsPerPage}
                pathPrefix="/blog/"
              />
                </div>
                <div className="col-12">
                  <ArticleList posts={posts} />
                </div>
              </div>
            </div>
          ) : null}
          </div>

    </div>
    )
}





export const blogListQuery = graphql`
  query blogListQuery($skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        title
        description
        postsPerPage
      }
    }
    file(relativePath: { eq: "LisbonReopening-2020-GettyImages-655540080.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_noBase64
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    allMdx(
      filter: { fields: { collection: { eq: "post" } } }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      totalCount
      edges {
        node {
          id
          excerpt
          timeToRead
          fields {
            collection
            slug
          }
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            category
            image {
              ...ImageFields
            }
          }
        }
      }
    }
  }
`


/*

CHECK ---- IMAGE FORMATS


export const squareImage = graphql`
  fragment squareImage on File {
    childImageSharp {
      fluid(maxWidth: 200, maxHeight: 200) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "images/image1.jpg" }) {
      ...squareImage
    }

    image2: file(relativePath: { eq: "images/image2.jpg" }) {
      ...squareImage
    }

    image3: file(relativePath: { eq: "images/image3.jpg" }) {
      ...squareImage
    }
  }
`

*/
