import React from 'react';
import { Link } from 'gatsby';


export default function Pagination({
    totalCount,
    currentPage = 1,
    pathPrefix,
    postsPerPage = 3
  }) {
    const totalPages = Math.ceil(totalCount / postsPerPage);
    const nextPage = currentPage + 1;
    const prevPage = (currentPage - 1 === 1) ? "" : currentPage - 1 ;

    if(totalPages === 1){
      return null;
    }

    return (
      <div className="pagination">
        <div className="pagination__btns">
          <Link
          className={`prev ${currentPage <= 1 ? "disabled": ""}`}
            disabled={currentPage <= 1 ? true : null}
            to={currentPage > 1 ? `${pathPrefix}${prevPage}` : null}
          >
            ←
          </Link>
          <div className="pageIndicator">
            {currentPage} of {totalPages}
          </div>

            <Link
            className={`next ${nextPage > totalPages ? "disabled": ""}`}
            disabled={nextPage > totalPages ? true : null}
            to={nextPage > totalPages ? null : `${pathPrefix}${nextPage}`}
          >
            →
          </Link>


        </div>
      </div>
    );
  }
  